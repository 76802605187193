/* Global Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Light Theme */
.light-theme {
  --bg-color: #ffffff;
  --text-color: #000000;
  --sidebar-bg: #f4f4f4;
  --navbar-bg: #2c1d63;
  --main-bg: #ffffff;
}

/* Dark Theme */
.dark-theme {
  --bg-color: #121212;
  --text-color: #e0e0e0;
  --sidebar-bg: #121212;
  --navbar-bg: #1a1a1a;
  --main-bg: #121212;
  
}

.app-container {
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* App Layout */
.app-layout {
  display: flex;
  height: calc(100vh - 55px); /* Adjust to avoid navbar overlap */
}

.sidebar {
  width: 250px;
  background-color: var(--sidebar-bg);
  padding: 10px;
  transition: background-color 0.3s ease;
}

.main-content {
  flex: 1;
  background-color: var(--main-bg);
  padding: 20px;
  overflow-y: auto;
}

/* Navbar styling */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--navbar-bg);
  padding: 10px 20px;
  color: white;
  height: 55px;
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.toggle-btn, .icon-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}

.toggle-btn:focus, .icon-btn:focus {
  outline: none;
}

.logo img {
  height: 40px;
  width: auto;
}

/* Search bar styles */
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-left: auto; /* Push the search bar to the right */
}

.input-search {
  height: 30px; /* Reduced height */
  width: 100px; /* Reduced width */
  border-style: none;
  padding: 8px 10px;
  font-size: 16px;
  letter-spacing: 1px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: var(--navbar-bg);
  color: #fff;
}

.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
}

.btn-search {
  width: 40px;
  height: 45px;
  border-style: none;
  font-size: 22px; /* Increased size of the icon */
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  color: #ffffff;
  background-color: transparent;
}

.btn-search:focus ~ .input-search {
  width: 250px; /* Expanded width when focused */
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search:focus {
  width: 250px; /* Expanded width when focused */
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(13, 107, 5, 0.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.icon-btn {
  font-size: 24px;
  margin-left: 10px;
}
