/* Base Background */
body {
  background: #f0e6ff;
  font-family: 'Source Sans Pro', sans-serif;
}

/* Dark Mode Styles */
.main-content.dark {
  background-color: #2a2a2a;
  color: white;
}

.main-content.light {
  background-color: #f9f4ff;
  color: #333;
}

/* Themed Action Buttons (Section 1) */
.actions-grid {
  display: flex;
  align-items: center;
  gap: 20px; /* Increased space between action buttons */
  margin-bottom: 20px; /* Add space between action buttons and tabs */
}

.select-all-checkbox {
  margin-right: 10px;
  
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 120px;
  height: 40px;
  background-color: #a29bfe;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.action-button:hover {
  background-color: #d4cbe8;
  color: #333;
}

/* Tabs Section (Section 2) */
.tabs-grid {
  display: flex;
  justify-content: space-between;
  background: #a29bfe;
  border-radius: 10px;
  margin-bottom: 20px; /* Add space between tabs and email list */
}

.tab {
  flex: 1;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  color: #fff;
  background: #a29bfe;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.tab.active {
  background: #fff;
  color: #5a5a5a;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.tab:hover {
  background: #d4cbe8;
  color: #333;
}

/* Panel Content (Section 3) */
.panel {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px; /* Increased padding for better spacing */
  overflow-y: auto;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.emails-grid {
  width: 100%;
  border-spacing: 0;
}

.email-row {
  padding: 10px 5px;
  transition: background-color 0.2s;
}

.email-row:hover {
  background-color: #f0e6ff;
}

.email-row td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.sender, .title, .time {
  padding: 0 5px;
}
.icon {
  color: #b6bb0e;
}

.icon:hover {
  color: #00a709;
}

/* Dark Mode Specific */
.main-content.dark .action-button {
  background-color: #64de4e;
  color: #000000;
}
.main-content.dark .action-button:hover {
  background-color: #07790c;
  color: #fff;
}

.main-content.dark .tab {
  background: #64de4e;
  color: #000000;
}
.main-content.dark .tab:hover{
  background: #07790c;
  color: #fff;
}
.main-content.dark .panel {
  background-color: #333;
  color: #fff;
}

.main-content.dark .email-row:hover {
  background-color: #555;
}
/* 11111111111111111111111111111111111111111111111111111111111111 */

/* Checkbox Base Styling */
.select-all-checkbox,
.email-row input[type="checkbox"] {
  margin-right: 10px;
  width: 15px; /* Bigger size for both modes */
  height: 15px;
  cursor: pointer;
  appearance: none; /* Remove default checkbox style */
  border: 1px solid #aaa;
  border-radius: 4px;
  background-color: white; /* Default background for light mode */
  position: relative;
}

/* Checked State (Light Mode Default) */
.select-all-checkbox:checked,
.email-row input[type="checkbox"]:checked {
  background-color: #0078d4; /* Default blue for light mode */
  border-color: #0078d4;
}

/* Hover Effect */
.select-all-checkbox:hover,
.email-row input[type="checkbox"]:hover {
  border-color: #555; /* Subtle hover effect */
}

/* Dark Mode Checkbox Styling */
.main-content.dark .select-all-checkbox,
.main-content.dark .email-row input[type="checkbox"] {
  background-color: #333; /* Dark background for unselected */
  border-color: #555;
}

.main-content.dark .select-all-checkbox:checked,
.main-content.dark .email-row input[type="checkbox"]:checked {
  background-color: #00a709; /* Green background for dark mode */
  border-color: #00a709;
}

/* Add checkmark for selected state */
.select-all-checkbox:checked::before,
.email-row input[type="checkbox"]:checked::before {
  content: "✔"; /* Checkmark for selected state */
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
