/* Background overlay for popup */
.compose-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup box styling */
.compose-content {
  background: #fff;
  width: 500px;
  max-width: 90%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

/* Header */
.compose-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.compose-header h2 {
  font-size: 18px;
  color: #333;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

/* Body section with fields */
.compose-body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.compose-body label {
  font-size: 14px;
  color: #555;
}

.compose-body input,
.compose-body textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.compose-body textarea {
  resize: vertical;
  min-height: 100px;
}

/* Footer with action buttons */
.compose-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.send-btn {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Icon buttons */
.icon-btn {
  background: none;
  border: none;
  color: #555; /* Light mode default icon color */
  font-size: 20px; /* Improved size for visibility */
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

/* Hover and active effects */
.icon-btn:hover {
  color: #333; /* Darker hover color in light mode */
  transform: scale(1.2); /* Slight zoom effect */
}

.icon-btn:active {
  transform: scale(1.1); /* Active click effect */
  color: #4a90e2; /* Accent color */
}

.delete-btn {
  background: none;
  border: none;
  color: #e74c3c;
  font-size: 18px;
  cursor: pointer;
}

/* Adjustments for light mode */
.light-theme .compose-content {
  background: #fff;
  color: #333;
}

.light-theme .icon-btn {
  color: #555;
}

.light-theme .icon-btn:hover {
  color: #000; /* Darker for better contrast in light mode */
}

/* Dark mode styling */
.dark-theme .compose-popup {
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay for dark mode */
}

.dark-theme .compose-content {
  background: #2c2c2c; /* Dark mode background */
  color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.dark-theme .compose-header h2 {
  color: #fff;
}

.dark-theme .compose-body label {
  color: #ddd;
}

.dark-theme .compose-body input,
.dark-theme .compose-body textarea {
  background: #444;
  color: #fff;
  border: 1px solid #555;
}

.dark-theme .compose-body input::placeholder,
.dark-theme .compose-body textarea::placeholder {
  color: #888;
}

.dark-theme .send-btn {
  background-color: #3b82f6;
}

.dark-theme .icon-btn {
  color: #64de4e; /* Softer icon color for dark mode */
}

.dark-theme .icon-btn:hover {
  color: #00a709; /* Brighten on hover for dark mode */
}

.dark-theme .delete-btn:hover {
  color: #e74c3c;
}
.dark-theme .delete-btn {
  color: #bbb; /* Brighten on hover for dark mode */
}