/* Sidebar styling */
.sidebar {
  width: 250px;
  height: 89vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #d3c5f0;
  background-color: var(--sidebar-bg-color); /* Set background color based on theme */
  color: var(--text-color); /* Set text color based on theme */
}

/* Light Mode Sidebar */
.sidebar.light {
  --sidebar-bg-color: #e6e0ff; /* Light purple background */
  --text-color: #000000; /* Dark text color for light mode */
}

.sidebar.dark {
  --sidebar-bg-color: #1d1a23; /* Dark purple background */
  --text-color: #e0e0e0; /* Light text color for dark mode */
}

.compose-btn {
  background-color: #a599e9; /* Slightly darker purple */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.compose-btn:hover {
  background-color: #9385e0;
  color: black;
}

.sidebar-sections {
  width: 0%;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #5c469c; /* Default text color */
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sidebar-item:hover {
  color: #000000;
}

.sidebar-item svg {
  margin-right: 10px;
}

/* Dark Mode specific styling */
.sidebar.dark .sidebar-item {
  color: #64de4e;
}
.sidebar.dark .sidebar-item:hover {
  color:  #07790c;
}
.sidebar.dark .compose-btn{
  background-color: #64de4e;
  font-weight: bold;/* Slightly darker purple */
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.sidebar.dark .compose-btn:hover{
  background-color:  #07790c;
}


