/* Navbar styling */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2c1d63; /* Dark purple color for navbar */
  padding: 10px 20px;
  color: white;
  height: 55px; /* Match navbar height */
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.toggle-btn, .icon-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}

.toggle-btn:focus, .icon-btn:focus {
  outline: none;
}

.logo img {
  height: 40px;
  width: auto;
}

/* Search bar styles */
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-left: auto; /* Push the search bar to the right */
}

.input-search {
  height: 30px; /* Reduced height */
  width: 100px; /* Reduced width */
  border-style: none;
  padding: 8px 10px;
  font-size: 16px;
  letter-spacing: 1px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #2c1d63; /* Same background color as navbar */
  color: #fff;
}

.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
}

.btn-search {
  width: 40px;
  height: 45px;
  border-style: none;
  font-size: 22px; /* Increased size of the icon */
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  color: #ffffff;
  background-color: transparent;
}

.btn-search:focus ~ .input-search {
  width: 250px; /* Expanded width when focused */
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search:focus {
  width: 250px; /* Expanded width when focused */
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.icon-btn {
  font-size: 24px;
  margin-left: 10px;
  background-color:#ffffff;
}
